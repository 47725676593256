@use "colors";
@use "global";

.sl-wraper {
    padding-top: 80px;
    .swagger-ui .prop-type {
        color: colors.$blue;
    }

    .arrow.arrow, 
    .swagger-ui section.models h4 svg {
        width: 24px;
        height: 24px;
        fill: colors.$icon-color;
    }

    .sl-unlocked-btn.sl-unlocked-btn {
        * {
            fill: colors.$icon-color;
        } 
        &.authorize {
            * {
                fill: colors.$white;
            } 
        }
  
    }

    .sl-logo {
        position: absolute;
        left: 20px;
        top: 10px;
    }

    .sl-header-info {
        position: absolute;
        top: 23px;
        left: 197px;
        font-size: 10px;
        .sl-info-label {
            color: colors.$white;
            background-color: colors.$icon-color;
            display: inline-block;
            border-radius: 10px;
            line-height: 9px;
            margin-left: 5px;
            padding: 1px 2px;
            &.sl-OAS3 {
                background-color: colors.$green;
            }
        }
    }


    .swagger-ui .auth-wrapper .authorize {
        margin-right: 0;
    }

    .scheme-container {
        padding-top: 14px;
        padding-bottom: 15px;
        box-shadow: none;
        margin-top: 0;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        right: 0;
    }

    .no-margin {
        & > span {
            &:last-child .opblock {
                    margin-bottom: 0
                };
        }
    }
    .opblock-tag-section {
        .opblock-tag {
            border-bottom: none;
            margin-top: 5px;
            padding: 0;
            margin-right: 0;
            &:hover {
                background: rgba(0,0,0,.02);
            }
            a.nostyle {
                font-size: 18px;
                color: colors.$black;
                font-weight: bold;
                span {
                    overflow-wrap: anywhere;
                }
            }
            small {
                font-size: 12px;
                color: colors.$black;
            }
            .markdown {
                padding-top: 3px;
                p {
                    margin: 0;
                }
            }
            .expand-operation {
                transform: translateY(5px);
            }
        }
        .opblock {
            border: solid 1px colors.$border-color;
            box-shadow: none;
            background: colors.$white;
            margin-bottom: 10px;
            .opblock-summary {
                .opblock-summary-control:focus{
                    outline: none;
                }
                .opblock-summary-method {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    background: none;
                    font-size: 13px;
                    color: colors.$black;
                    width: 80px;
                    font-weight: 500;
                    border-right: solid 3px colors.$blue;
                    border-radius: 0;
                }
                .opblock-summary-path {
                    font-size: 13px;
                }
                .opblock-summary-description {
                    font-size: 12px;
                }
            }
            &.opblock-post .opblock-summary-method {
                border-color: colors.$green!important;
            }
            &.opblock-delete .opblock-summary-method {
                border-color: colors.$red!important;
            }
            &.opblock-put .opblock-summary-method {
                border-color: colors.$orenge!important;
            }
        }
    }

    .sl-info-top-box {
        margin: 20px;
        padding: 20px;
        margin-top: 0;
        margin-bottom: 15px;
        padding-bottom: 0;
        background-color: #f6f8f9;
        border: solid 1px colors.$border-color;
        border-radius: 4px;
        color: colors.$black;
        font-size: 13px;
        font-weight: normal;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        position: relative;
        min-height: 40px;
        .sl-arrow {
            position: absolute;
            top: 18px;
            right: 10px;
            left: 10px;
            text-align: right;
            cursor: pointer;
            z-index: 10;
        }
        pre {
            word-break: break-word;
            white-space: pre-wrap;
            -moz-white-space: pre-wrap;  
            font-size: 13px;
            strong {
                font-weight: 500;
                margin-bottom: 5px;
                margin-top: 10px;
                display: inline-block;
                &:first-child {
                    margin-top: 0;
                } 
            }
            margin-bottom: 20px
        }
        h3 {
            font-size: 18px;
            margin-bottom: 20px;
        }
        h4 {
            font-size: 14px;
            margin-bottom: 10px;
        }
        small {
            &:last-child {
                pre {
                    margin-top: 10px;
                }
            }
        }
        &.sl-closed {
            small, h4, span {
                display: none;
            }
            h3 {
                margin-bottom: 0;
            }
            .sl-arrow svg {
                transform: rotate(-90deg);
            } 
        }
    }

    @media (max-width:global.$md) {
        .opblock-summary.opblock-summary.opblock-summary {
            padding-top: 5px;
            padding-bottom: 5px;
            .opblock-summary-method {
                padding-top: 5px!important;
            }
            .opblock-summary-path {
                margin-bottom: 5px;
            } 
            .opblock-summary-path,
            .opblock-summary-description {
                max-width: 100%;
                display: block;
            }
        }
      }
    @media (max-width: global.$sm) {
        padding-top: 70px;
        .sl-unlocked-btn.sl-unlocked-btn.sl-unlocked-btn.sl-unlocked-btn {
           padding: 5px;
            min-width: 10px;
            span {
                display: none;
            }
            svg {
                position: static!important;
            }
        }
        .sl-info-top-box {
            padding: 10px;
            margin-top: 0;
            padding-bottom: 0;
            min-height: 32px;
            margin-left: 10px;
            margin-right: 10px;
            .sl-arrow {
                top: 9px;
            }
        }
        .wrapper {
            padding-left: 10px;
            padding-right: 10px;
        }
        .sl-logo {
            width: 120px;
        }
        .sl-header-info {
            left: 150px;
            top: 26px;
        }
    }
}


