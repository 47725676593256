@use "colors";
@use "global";

.sl-wraper {
    .response-col_links i {
        color: colors.$black;
        font-weight: normal;
        font-size: 13px;
        font-style: normal
    }
    .model-example {
        .model-box {
            background-color: colors.$grey-bg;
        }
    }
    .response-control-media-type__accept-message {
        color: colors.$blue;
    }
    ul.tab {
        margin-bottom: 10px;
        .tablinks {
            font-size: 14px;
            color: colors.$black;
        }
    }
    .opblock-section {
        .opblock-section-header {
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: solid 1px colors.$border-color;
            box-shadow: none;
            .content-type {
                min-width: 160px;
            }
        }
        .tab-header {
            padding-top: 15px;
            height: 50px;
            h4 {
                font-size: 14px;
                color: colors.$black;
                font-weight: bold;
                padding-bottom: 14px;
                border-bottom: solid 5px colors.$blue;
                display: inline-block;
                flex: none;
                span {
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .btn {
            box-shadow: none;
            padding: 6px 20px;
            border-radius: 4px;
            border: solid 1px colors.$blue;
            color: colors.$blue;
            font-size: 14px;
            font-weight: 500;
            &:hover {
                background-color: colors.$blue;
                color: colors.$white;
            }
            &.cancel {
                border: solid 1px colors.$red;
                color: colors.$red;
                &:hover {
                    background-color: colors.$blue;
                    color: colors.$white;
                    border: solid 1px colors.$blue;
                }
            }
        }
        .parameters {
            .col_header {
                font-size: 13px;
                color: colors.$black;
                font-weight: normal;
                border-bottom: solid 1px colors.$border-color;
            }
            .parameter__name {
                font-size: 14px;
                font-weight: normal;
                color: colors.$black;
                margin-top: 15px;
                margin-bottom: 12px;
                span {
                    color: colors.$red;
                }
                &::after {
                    color: colors.$red;
                }
            }
            .parameter__type {
                font-size: 12px;
                font-weight: normal;
                color: colors.$black
            }
            .parameter__in {
                font-size: 12px;
                font-weight: normal;
                color: colors.$icon-color;
            }
            .parameters-col_description {
                .markdown p, .renderedMarkdown p {
                    font-size: 14px;
                    font-weight: normal;
                    color: colors.$black
                }
            }
            .model-box {
                background-color: colors.$grey-bg;
                width: 100%;
            }
            .model {
                td {
                    padding-top: 5px;
                    padding-top: 5px;
                }
            }
        }
    }
    .model-box-control:focus,
    .models-control:focus,
    .opblock-summary-control:focus{
        outline:none !important;
    }
    .opblock-body {
        .btn {
            border-radius: 4px;
            font-weight: 500;
            border: solid 1px colors.$blue;
            box-shadow: none!important;
        }
        .btn.execute {
            background-color: colors.$blue;
            color: colors.$white;
            &:hover {
                background-color: colors.$white;
                color: colors.$blue;
            }
        }
        .btn-clear {
            border-left: none;
            color: colors.$blue;
        }
    }
    .responses-wrapper {
        .content-type {
            min-width: 400px;
        }
        h4 {
            font-size: 14px;
            color: colors.$black;
            font-weight: normal;
            padding-bottom: 5px;
        }
        .opblock-section-header { 
            box-shadow: none;
            border-bottom: solid 1px colors.$border-color;
            h4 {
                font-size: 14px;
                color: colors.$black;
                font-weight: bold;
                padding-bottom: 0;
            }
        } 
        .col_header {
            font-size: 13px;
            color: colors.$black;
            font-weight: normal;
            border-bottom: solid 1px colors.$border-color;
        } 
        .response-col_status {
            font-size: 14px;
            font-weight: normal;
            color: colors.$black;
        }
        .response-col_description__inner {
           .markdown p, .renderedMarkdown p {
                font-size: 14px;
                font-weight: normal;
                color: colors.$black;
                padding: 0;
                margin: 0;
           }
        }
        .response-control-media-type__title {
            color: colors.$black;
            font-size: 11px;
            display: block;
            margin-bottom: 5px;
        }
    }
    @media (max-width: global.$md) {
        .response-col_links {
            display: none;
        }
    }
    @media (max-width: global.$sm) {
        .responses-wrapper {
            .content-type {
                min-width: 150px;
            }
        }
    }


}

.swagger-ui .model-box-control:focus,.swagger-ui .models-control:focus,.swagger-ui .opblock-summary-control:focus{outline:auto}
