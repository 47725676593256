@use "colors";
@use "global";

.sl-wraper {

    .modal-ux {
        .scope-def {
            padding-bottom: 8px;
        } 
        .auth-container .errors {
            margin-left: 0;
            margin-right: 0;
            color: colors.$black;
            font-weight: normal;
        }
        .modal-ux-header {
            border-bottom: solid 1px colors.$border-color;
            h3 {
                font-size: 16px;
                font-weight: bold;
                color: colors.$black;
            }
        }
        .modal-btn.modal-btn {
            margin-right: 10px;
        }
        .auth-btn-wrapper {
            display: block;
            text-align: right;
            .authorize {
                float: right;
            }
        }
        .auth-container {
            padding: 0!important;
            border: none;
            h4 {
                color: colors.$black;
            }
        }
        h6 {
            display: none;
        }
        .auth-container.auth-container {
            .wrapper {
                label[for=password_type] {
                    transform: translateY(4px);
                }
                code {
                    display: inline-block;
                    padding: 9px 0;

                    font-size: 13px;
                    color: black;
                    font-weight: normal;
                }
            }
            p {
                font-size: 13px;
                font-weight: normal;
                color: colors.$icon-color;
                position: relative;
                margin-bottom: 15px;
                &.flow {
                    margin-bottom: 20px;
                }
                code {
                    position: absolute;
                    left: 130px;
                }
            }
            .scope-def p {
                color: colors.$black;
            }

            label {
                display: inline-block;
                width: 128px;
                font-size: 13px;
                color: colors.$black;
                font-weight: normal;
                margin-bottom: 0;
            }
            #password_type {
                transform: translateY(-4px);
            }
            .checkbox {
                label {
                    display: flex;
                    flex-direction: row;
                }
            }
            .scopes {
                h2 {
                    font-size: 13px;
                    color: colors.$black;
                    font-weight: normal;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    a {
                        &:first-child {
                            margin-left: 74px;
                        }
                        font-size: 13px;
                        color: colors.$blue;
                    }
                }
            }
            section {
                display: inline-block;
                width: calc(100% - 128px);
                .content-type, select, input[type=file], input[type=text], input[type=password] {
                    max-width: 100%;
                    min-width: 100px;
                }
            }
        } 
    }

    @media (max-width: global.$sm) {
        .modal-ux {
            .auth-btn-wrapper {
                padding-top: 0;
            }
            .scope-def {
                padding-bottom: 0px;
            } 
            .auth-container.auth-container {
                h4 {
                    margin-bottom: 10px!important;
                }
                .scopes {
                    h2 {
                        a {
                            &:first-child {
                                margin-left: 0px;
                            }
                        }
                    }
                }
                .wrapper {
                    code {
                        padding-top: 5px;
                    }
                    & > label {
                        display: block;
                        width: 100%;
                    }
                    label[for=password_type] {
                        transform: translateY(0);
                    }
                    label[for=password_type] + .block-tablet {
                        margin-top: 10px;
                    }
                }
                section {
                    width: 100%;
                }
                .block-tablet {
                    input {
                        margin-bottom: 10px;
                    }
                    select {
                        margin-bottom: 7px;
                    }
                }
                p {
                    margin-bottom: 10px;
                    code {
                        position: static;
                        display: block;
                        margin-top: 5px;
                    } 
                    &.flow {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}


