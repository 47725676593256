@use "colors";

$sm: 576px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1600px;

@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
  }

* {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
}

.sl-wraper.sl-wraper {
    .information-container,
    .scheme-container .schemes label,
    .errors-wrapper {
        display: none
    }
    table.model, .highlight-code {
        font-size: 12px;
        font-size: 12px;
        font-weight: 700;
        color: colors.$black;
        td, p {
            color: colors.$black;
        }
        * {
            font-family: Consolas,monaco,monospac !important;
        }
    }
    & .example {
        padding: 10px;
    }

    & input[type=text] {
        border-radius: 4px;
        border: solid 1px colors.$border-color;
        font-size: 12px;
        color: colors.$black;
    }

    & input[type=file] {
        border-radius: 4px;
        border: solid 1px colors.$border-color;
        font-size: 12px;
        color: colors.$black;
    }

    & input {
       &::placeholder {
            color:colors.$placeholder;
        }
    }
    .content-type, select {
        font-size: 12px;
        color: black;
        font-weight: normal;
        border: solid 1px colors.$border-color;
        box-shadow: none;
        background-color: #fff;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .content-type, select, input[type=file], input[type=text], input[type=password]  {
        width: 100%;
        min-width: 100px;
        max-width: 400px;
        box-shadow: none;
        padding: 9px 10px;
        &[type=file] {
            max-width: 378px;
        }
        &[type=password] {
            padding: 6px 10px;
            padding-top: 7px;
        }
        &:hover {
            border: solid 1px colors.$blue;
        }
        &:focus-visible {
            border: solid 1px colors.$blue;
            box-shadow: none!important;
            outline: none!important
        }
        &.invalid {
            background-color: colors.$white;
            border: solid 1px colors.$red;
        }
        &[disabled] {
            background-color: #fafafa;
        }
    }

    .btn.authorize, .auth.btn-done {
        min-width: 100px;
        padding: 9px 20px;
        border-radius: 4px;
        background-color: colors.$blue;
        font-weight: 500;
        font-size: 14px;
        color: colors.$white;
        position: relative;
        border: solid 1px colors.$blue;
        box-shadow: none!important;
        span {
            float: none;
            padding: 0;
        }
   
        svg {
            position: absolute;
            top: 8px;
            left: 20px;
            fill: colors.$white;
        }
        &:hover {
            background-color: colors.$white;
            color: colors.$blue;
            svg {
              * {
                fill: colors.$blue!important;
              }  
            }
        }
    }
    .auth.btn-done {
        padding: 9px 20px;
        background-color: colors.$white;
        color: colors.$blue;
        &:hover {
            background-color: colors.$blue;
            color: colors.$white;
        }
    }
    .sl-unlock-icon, .sl-lock-icon {
        display: none;
    }
    .unlocked .sl-unlock-icon,
    .locked .sl-lock-icon {
        display: inline-block;
    }
}

.authorization__btn.unlocked {
    opacity: 1!important
}

.swagger-ui .wrapper {
    max-width: 100%;
}