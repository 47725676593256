@use "colors";

.sl-wraper {
    .swagger-ui .models {
        border: solid 1px colors.$border-color;
        margin-top: 20px;
        &.is-open {
            padding-bottom: 0;
        }
        h4 {
            border: none!important;
            span {
                font-size: 18px;
                color: colors.$black;
                font-weight: bold;
            }
        }
        .model-container.model-container {
            background: none;
            border: solid 1px colors.$border-color;
            margin: 10px;
            margin-top: 0;

            .model-box {
                padding: 0;
                display: block;
            }
            .model-title {
                padding: 8px;
                position: relative;
                display: block;
                font-size: 13px;
                color: colors.$black;
                font-weight: 500;
                &:hover {
                    background: rgba(0,0,0,.02);
                }
            }
            .model-toggle {
                position: absolute;
                top: 5px;
                right: 3px;
                &::after {
                    background-size: 130%;
                    opacity: .6;
                }
            }
            .brace-open, .brace-close {
                margin: 0 auto;
                display: block;
                background: colors.$grey-bg;
                width: calc(100% - 40px);
                padding: 10px
            }
            .brace-open {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
            .brace-close {
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
                margin-bottom: 10px;
            }
            .inner-object {

                width: calc(100% - 20px);
                background: colors.$grey-bg;
                display: block;
                margin: 0 auto;
                .property-row {
                    td {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }
                .model-toggle {
                    position: static;
                    transform: translateY(7px);
                    &::after {
                        background-size: 100%;
                    }
                }
            }
        }
    }
}


